<template>
    <v-container>
      <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
        <v-row>
          {{text}}
          <v-spacer></v-spacer>
          <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-row>
      </v-snackbar>
      <v-row justify="center" id="hotel-details">
        <v-col cols="12" md="3" v-show="!packageDetails.visaOnly">
          <v-card v-if="startCity && endCity" :disabled="visaOnly" outlined tile color="#f2f2f2">
            <v-card-title class="primary pa-2">
              <h2 class="text-h6 white--text text-capitalize text-center mb-2 font-weight-medium"><v-icon left color="white">mdi-timeline-clock</v-icon>{{$t('hotel.timeline.timeline')}}</h2>
            </v-card-title>
            <v-card-text class="pb-0 pl-0">
              <v-timeline
              align-top
              dense
              >
                <v-timeline-item
                v-for="n in startCity.cityNightNumber"
                :key="n"
                color="primary"
                small
                >
                  <strong><v-icon color="error">mdi-map-marker</v-icon>{{$t(`hotel.hotels.${startCity.cityName}`)}} - {{$t('hotel.hotels.day')}} {{n}}</strong>
                  <br>
                  <small class="body-2">{{new Date(packageDetails.dates[n - 1]).toDateString()}}</small>
                  <v-checkbox v-if="n === 1 && packageDetails.firstNightOptional" color="error" v-model="firstNightOptional">
                    <template v-slot:label>
                      <span class="body-1 primary--text">{{$t('hotel.hotels.early')}}</span>
                    </template>
                  </v-checkbox>
                </v-timeline-item>

                <v-timeline-item
                v-for="n in endCity.cityNightNumber"
                :key="Number(startCity.cityNightNumber) + n"
                color="headersText"
                small
                >
                  <strong><v-icon color="error">mdi-map-marker</v-icon>{{$t(`hotel.hotels.${endCity.cityName}`)}} - {{$t('hotel.hotels.day')}} {{Number(startCity.cityNightNumber) + n}}</strong>
                  <br>
                  <small class="body-2">{{new Date(packageDetails.dates[(Number(startCity.cityNightNumber) + n) - 1]).toDateString()}}</small>
                  <v-checkbox v-if="n === endCity.cityNightNumber && packageDetails.lastNightOptional" color="error" v-model="lastNightOptional">
                    <template v-slot:label>
                      <span class="body-1 primary--text">{{$t('hotel.hotels.late')}}</span>
                    </template>
                  </v-checkbox>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="9" class="order-first order-md-last">
          <div class="d-flex justify-space-between">
            <v-checkbox color="primary" v-model="visaOnly">
              <template v-slot:label>
                <span class="headline primary--text">{{$t('hotel.hotels.visaOnly')}}</span>
              </template>
            </v-checkbox>
            <v-btn
            color="primary"
            elevation="0"
            v-if="visaOnly"
            @click="save"
            class="mt-3"
            large
            >
              {{$t('hotel.hotels.save')}}
            </v-btn>
          </div>
          <v-card v-show="!packageDetails.visaOnly" :disabled="visaOnly" class="mx-auto" color="#f2f2f2" outlined tile>
            <v-card-title class="primary pa-2">
              <h2 class="text-h6 white--text text-capitalize font-weight-medium"><v-icon left color="white">mdi-office-building</v-icon>{{$t('hotel.hotels.title')}}</h2>
            </v-card-title>
            <v-card-text>
              <p class="my-1 #555--text">{{$t('hotel.hotels.nightsNote', {nights: packageDetails.max_nights})}}</p>
              <div v-for="(item) in packageDetails.cities" :key="item.cityID" class="my-5">
                  <v-card tile outlined  class="px-2 pt-5 pb-2">
                    <v-row justify="space-between" align="center" class="px-3">
                      <div class="d-flex align-end">
                        <v-img v-if="item.code === 'MAC'" max-width="100" class="mx-3" :src="makkahHotel.image"></v-img>
                        <v-img v-if="item.code === 'MED'" max-width="100" class="mx-3" :src="madinaHotel.image"></v-img>
                        <div>
                          <p class="text-h5 font-weight-medium mb-0">{{$t(`hotel.hotels.${item.cityName}`)}}</p>
                          <h3
                          class="text-h5 font-weight-medium headersText--text text-capitalize"
                          v-text="item.code === 'MED' ? madinaHotel.hotelName : makkahHotel.hotelName"
                          >
                          </h3>
                        </div>
                      </div>
                      <div>
                        <v-btn :disabled="item.cityNightNumber < 1" block color="headers lighten-2 mt-2 white--text" elevation="0" class="" @click="changeHotel(item.code)">
                          <v-icon left>mdi-office-building-cog</v-icon>
                          {{$t('hotel.hotels.changeHotel')}}
                        </v-btn>

                        <div class="d-flex mt-5 align-center">
                            <v-btn
                            :disabled="(packageDetails.cities[0].cityNightNumber + packageDetails.cities[1].cityNightNumber) >= packageDetails.max_nights" small elevation="0"
                            color="headers white--text"
                            class="pa-5 font-weight-bold text-h6 rounded-0"
                            @click="changeHotelsNights(item.cityID, 'inc')"
                            >+</v-btn>

                            <div class="headers white--text lighten-2 py-1 px-2 text-h6">{{$tc('hotel.hotels.nights', item.cityNightNumber)}}</div>

                            <v-btn
                            :disabled="(item.cityNightNumber <= madinaHotel.min_nights && item.code === 'MED') || (item.cityNightNumber <= makkahHotel.min_nights && item.code === 'MAC')" small elevation="0"
                            color="headers white--text"
                            class="pa-5 font-weight-bold text-h6 rounded-0"
                            @click="changeHotelsNights(item.cityID, 'dec')"
                            >-</v-btn>
                        </div>
                      </div>
                    </v-row>
                    <p class="mt-5 mb-0 body-1 text-capitalize font-weight-medium"><span class="headersText--text">{{$t('hotel.hotels.occupancy.occupancy')}}:</span> {{adults + oldChildren === 1 ? $t('hotel.hotels.occupancy.single') : (adults + oldChildren === 2 ? $t('hotel.hotels.occupancy.double'): (adults + oldChildren === 3 ? $t('hotel.hotels.occupancy.triple') : $t('hotel.hotels.occupancy.quad')))}}</p>
                  </v-card>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions v-if="packageDetails.max_nights >= Number(makkahHotel.min_nights) + Number(madinaHotel.min_nights)">
              <div>
                <v-checkbox
                v-if="(packageDetails.cities[0].cityNightNumber + packageDetails.cities[1].cityNightNumber) < packageDetails.max_nights"
                v-model="noticeAccepted"
                :color="noticeAccepted ? 'success' : 'error'"
                >
                  <template v-slot:label>
                    <span class="error--text">
                      <!-- Note: your stay is {{packageDetails.max_nights}} nights but you chose {{packageDetails.cities[0].cityNightNumber + packageDetails.cities[1].cityNightNumber}} nights -->
                      {{$t('hotel.hotels.stayNote', {stay: packageDetails.max_nights, nights: packageDetails.cities[0].cityNightNumber + packageDetails.cities[1].cityNightNumber})}}
                    </span>
                  </template>
                </v-checkbox>

                <v-checkbox
                v-if="packageDetails.cities.find(item => item.code === 'MED').cityNightNumber < 1"
                v-model="nightsNoticeAccepted"
                :color="nightsNoticeAccepted ? 'success' : 'error'"
                >
                  <template v-slot:label>
                    <span class="error--text">{{$t('hotel.hotels.medNote')}}</span>
                  </template>
                </v-checkbox>
              </div>

              <v-spacer></v-spacer>
              <v-btn
              :disabled="packageDetails.cities.find(item => item.code === 'MAC').cityNightNumber < makkahHotel.min_nights
              || packageDetails.cities.find(item => item.code === 'MED').cityNightNumber < madinaHotel.min_nights ||
              (packageDetails.cities.find(item => item.code === 'MED').cityNightNumber < 1 && !nightsNoticeAccepted)"
              color="primary"
              elevation="0"
              @click="save"
              >
                {{$t('hotel.hotels.save')}}
              </v-btn>
            </v-card-actions>
            <p v-else class="ma-3 error--text">{{$t('hotel.hotels.increaseDates')}}</p>
          </v-card>
          <v-alert
          border="left"
          color="indigo"
          dark
          v-show="packageDetails.visaOnly"
          >
            No hotels are available for these dates right now. You can continue with a visa only.
          </v-alert>
        </v-col>
      </v-row>

      <!-- select hotel dialog -->
      <v-dialog max-width="700" v-model="showHotelsList">
        <v-card color="#f2f2f2">
          <v-card-title class="primary white--text d-flex justify-space-between">
            <div>{{$t('hotel.hotels.selectHotel')}}</div>
            <v-btn icon color="white" large @click="showHotelsList = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="pa-3">
            <div v-for="item in cityHotels" :key="item.hotelID" class="d-flex justify-space-between align-end hotel-card">
              <div class="d-flex align-end">
                <img width="20%" class="mx-2" :src="item.image" alt="">
                <h3
                class="text-h5 ml-5 font-weight-medium headersText--text text-capitalize"
                v-text="item.hotelName"
                >
                </h3>
              </div>
              <v-btn elevation="0" color="primary white--text"
              @click="setCityHotel(item)"
              >
              {{$t('hotel.hotels.select')}}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      madinaHotels: [],
      makkahHotels: [],
      cityHotels: [],
      editCity: '',
      showHotelsList: false,
      noticeAccepted: false,
      snackbar: false,
      color: '',
      text: '',
      nightsNoticeAccepted: false,
      startCity: null,
      endCity: null,
      visaOnly: false,
      firstNightOptional: true,
      lastNightOptional: true
    }
  },
  computed: {
    ...mapState(['adults', 'oldChildren', 'packageDetails', 'makkahHotel', 'madinaHotel', 'destinationAirport'])
  },
  methods: {
    changeHotelsNights (cityId, method) {
      this.$store.dispatch('changeHotelsNights', { id: cityId, action: method })
    },
    changeHotel (city) {
      this.editCity = city
      if (city === 'MED') this.cityHotels = this.madinaHotels
      else this.cityHotels = this.makkahHotels
      this.showHotelsList = true
    },
    setCityHotel (hotel) {
      this.editCity === 'MED' ? this.$store.dispatch('setMadinaHotel', hotel) : this.$store.dispatch('setMakkahHotel', hotel)
      this.showHotelsList = false
    },
    save () {
      let mekkaDates
      let medinahDates
      if (this.startCity.code === 'MAC') {
        mekkaDates = {
          from: this.packageDetails.dates[0],
          to: this.packageDetails.dates[this.startCity.cityNightNumber - 1]
        }
        medinahDates = {
          from: this.packageDetails.dates[this.startCity.cityNightNumber],
          to: this.packageDetails.dates[this.startCity.cityNightNumber + this.endCity.cityNightNumber - 1]
        }
      } else {
        medinahDates = {
          from: this.packageDetails.dates[0],
          to: this.packageDetails.dates[this.startCity.cityNightNumber - 1]
        }
        mekkaDates = {
          from: this.packageDetails.dates[this.startCity.cityNightNumber],
          to: this.packageDetails.dates[this.startCity.cityNightNumber + this.endCity.cityNightNumber - 1]
        }
      }
      if ((this.packageDetails.cities[0].cityNightNumber + this.packageDetails.cities[1].cityNightNumber) < this.packageDetails.max_nights && !this.visaOnly) {
        if (this.noticeAccepted) {
          this.$router.push({
            name: 'packageBook',
            params: {
              id: this.$store.state.packageDetails.id,
              visaOnly: false,
              firstNightOptional: false,
              lastNightOptional: false,
              startCity: this.startCity.code,
              medinahDates: medinahDates,
              mekkaDates: mekkaDates
            }
          })
        } else {
          this.snackbar = true
          this.color = 'warning'
          this.text = 'Please accept the notice to continue'
        }
      } else {
        this.$router.push({
          name: 'packageBook',
          params: {
            id: this.$store.state.packageDetails.id,
            visaOnly: this.visaOnly,
            firstNightOptional: this.firstNightOptional,
            lastNightOptional: this.lastNightOptional,
            startCity: this.startCity.code,
            medinahDates: medinahDates,
            mekkaDates: mekkaDates
          }
        })
      }
    }
  },
  mounted () {
    if (this.packageDetails.cities[0].cityNightNumber + this.packageDetails.cities[1].cityNightNumber === 0) this.visaOnly = true
  },
  created () {
    if (this.packageDetails) {
      if (this.packageDetails.visaOnly) this.visaOnly = true
      console.log('visa', this.packageDetails)
      this.firstNightOptional = this.packageDetails.firstNightOptional
      this.lastNightOptional = this.packageDetails.lastNightOptional
      this.packageDetails.cities.forEach(element => {
        if (element.code === 'MED') {
          if (this.destinationAirport.Code === 'MED') this.startCity = element
          else this.endCity = element
          if (this.packageDetails.max_nights <= 3) element.cityNightNumber = 0
          this.madinaHotels = element.listHotels
          this.$store.dispatch('setMadinaHotel', element.listHotels[0])
        } else {
          if (this.destinationAirport.Code === 'JED') this.startCity = element
          else this.endCity = element
          if (this.packageDetails.max_nights <= 3) element.cityNightNumber = this.packageDetails.max_nights || 2
          this.makkahHotels = element.listHotels
          this.$store.dispatch('setMakkahHotel', element.listHotels[0])
        }
      })
    } else this.$router.push({ path: '/' })
  }
}
</script>

<style>
.hotel-card {
  padding: 12px;
  background-color: white;
  margin: 12px 0;
}
</style>
